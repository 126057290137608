import styled from "@emotion/styled";
import { HomeRounded, SensorsOutlined } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Card,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment-timezone";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import EventGroupCardHeader from "../components/Events/components/EventCardHeader";
import EventDetailCards from "../components/Events/components/EventDetailCards";
import EventPayloadTable from "../components/Events/components/EventPayloadTable";
import EventsDistributionChart from "../components/Events/components/EventsDistributionChart";
import ApiScreenError from "../components/common/error-components/ApiScreenError";
import { CopyLink } from "../components/common/share-and-collaborate/CopyLink";
import { LoaderInCenter } from "../components/common/styled-components";
import { StyledCardV2 } from "../components/common/styled-components/Card.styled";
import { L3ContainerMain } from "../components/common/styled-components/collaborate";
import { Message } from "../components/common/ui-components/Message";
import {
  BROWSER_EVENT_SUB_TYPES,
  EVENT_TYPE_ERROR,
  EVENT_TYPE_OUTAGE,
} from "../constants/eventConstants";
import { useAxios } from "../hooks/useAxios";
import { useSelectedTenant } from "../hooks/useSelectedTenant";
import { HTTP_STATUS } from "../shared/enums/http-status";
import { errorMessage } from "../shared/intl/error-messages";
import { colors, typography } from "../shared/theme-constants";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4, 8),
  [theme.breakpoints.down("lt")]: { padding: `${theme.spacing(5)}` },
  borderRadius: 12,
  overflow: "unset",
  backgroundColor: colors.white,
  position: "relative",
}));

const StyledTitle = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  fontWeight: typography.font_weight.semiBold,
  lineHeight: theme.typography.pxToRem(30),
  color: "var(--charcoal-grey)",
  marginBottom: "24px",
  [theme.breakpoints.down("tm")]: { fontSize: theme.typography.pxToRem(12) },
}));

const DEFAULT_TIMEZONE = "Etc/UTC";

const EventGroupCollaborateScreen = () => {
  const selectedTenant = useSelectedTenant();
  // Get the tenant configs
  const tenantConfigs = useSelector((state) => state.tenantConfigs.configs);
  // Get the timezone from the tenant configs
  let timezone = tenantConfigs?.timezone?.name ? tenantConfigs.timezone.name : DEFAULT_TIMEZONE;
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lt"));
  const { eventGroupId } = useParams();
  const {
    data: eventGroupData,
    isLoading,
    isError,
    error,
  } = useAxios(`events/groups/${eventGroupId}`, {
    params: {
      tenant_id: selectedTenant,
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const calculateDuration = () => {
    let current_time_stamp = null;
    let event_group_start_time = moment(eventGroupData?.event_group_ts_start).tz(DEFAULT_TIMEZONE);

    let event_group_duration = 0;

    if (eventGroupData?.status === "Active") {
      // Get browser's Time
      current_time_stamp = moment().tz(DEFAULT_TIMEZONE);
      event_group_duration = moment.duration(current_time_stamp.diff(event_group_start_time));
    } else if (eventGroupData?.status === "Closed") {
      current_time_stamp = moment(eventGroupData?.event_group_ts_end).tz(DEFAULT_TIMEZONE);
      event_group_duration = moment.duration(current_time_stamp.diff(event_group_start_time));
    }

    const days = event_group_duration.days();
    const hours = event_group_duration.hours().toString().padStart(2, "0");
    const minutes = event_group_duration.minutes().toString().padStart(2, "0");
    let formattedDuration = `${days > 0 ? days + "d" : ""} ${hours > 0 ? hours + "h" : ""} ${
      minutes > 0 ? minutes + "mins" : ""
    }`;
    return formattedDuration;
  };

  const renderTimeAndDuration = () => {
    return (
      <Stack
        direction="row"
        sx={(theme) => ({
          mt: 13,
          justifyContent: "space-between",
          [theme.breakpoints.down("lt")]: { mt: 0 },
        })}
      >
        <Stack direction="column" gap={2}>
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(16),
              [theme.breakpoints.down("lt")]: { fontSize: theme.typography.pxToRem(10) },
              fontWeight: typography.font_weight.light,
            })}
          >
            Start of Event
          </Typography>
          <Typography
            sx={(theme) => ({
              fontWeight: typography.font_weight.semiBold,
              [theme.breakpoints.down("lt")]: { fontSize: theme.typography.pxToRem(10) },
            })}
          >
            {moment
              .tz(eventGroupData.event_group_ts_start, DEFAULT_TIMEZONE)
              .tz(timezone)
              .format("ddd, Do MMM, YYYY, hh:mm A z")}
          </Typography>
        </Stack>
        {eventGroupData.status === "Closed" && (
          <Stack direction="column" gap={2}>
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(16),
                [theme.breakpoints.down("lt")]: { fontSize: theme.typography.pxToRem(10) },
                fontWeight: typography.font_weight.light,
              })}
            >
              End of Event
            </Typography>
            <Typography
              sx={(theme) => ({
                fontWeight: typography.font_weight.semiBold,
                [theme.breakpoints.down("lt")]: { fontSize: theme.typography.pxToRem(10) },
              })}
            >
              {moment
                .tz(eventGroupData.event_group_ts_end, DEFAULT_TIMEZONE)
                .tz(timezone)
                .format("ddd, Do MMM, YYYY, hh:mm A z")}
            </Typography>
          </Stack>
        )}
        <Stack direction="column" gap={2}>
          <Stack direction="row" sx={(theme) => ({ alignItems: "center" })}>
            <img
              width="11px"
              height="15px"
              src={`${process.env.REACT_APP_CDN_BASE_URL}/icons/${
                eventGroupData?.status === "Active" ? "duration_red" : "duration"
              }.svg`}
              style={{ marginRight: "4px" }}
            />
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(16),
                [theme.breakpoints.down("lt")]: { fontSize: theme.typography.pxToRem(10) },
                fontWeight: typography.font_weight.light,
                color: eventGroupData?.status === "Active" ? "var(--red-2)" : "var(--sonic-silver)",
              })}
            >
              Duration
            </Typography>
          </Stack>
          <Typography
            sx={(theme) => ({
              color: eventGroupData?.status === "Active" ? "var(--red-2)" : "var(--sonic-silver)",
              fontSize: theme.typography.pxToRem(16),
              [theme.breakpoints.down("lt")]: { fontSize: theme.typography.pxToRem(10) },
              fontWeight: typography.font_weight.semiBold,
            })}
          >
            {calculateDuration()}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  if (isLoading) {
    return (
      <L3ContainerMain id={eventGroupId} style={{ backgroundColor: "var(--grey-50)" }}>
        <LoaderInCenter />
      </L3ContainerMain>
    );
  }

  if (isError) {
    if (error.response?.status === HTTP_STATUS.NOT_FOUND) {
      return <ApiScreenError error={error} resourceType="Group" />;
    } else {
      return (
        <Box pb="50px">
          <Message>{errorMessage.CARD_ERROR}</Message>
        </Box>
      );
    }
  }
  return (
    <L3ContainerMain
      id={eventGroupId}
      style={{
        backgroundColor: "var(--grey-50)",
        alignItems: "center",
        ...(isSmallScreen && { position: "fixed", height: "auto", overflowY: "auto" }),
      }}
    >
      <Box
        sx={(theme) => ({
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "40px",
          marginBottom: "40px",
          paddingBottom: "50px",
          width: "100%",
          [theme.breakpoints.down("lt")]: { overflow: `scroll`, marginTop: "0px" },
        })}
      >
        <Stack
          gap={7}
          direction="column"
          sx={(theme) => ({
            height: "100%",
            maxWidth: theme.typography.pxToRem(1200),
            width: "100%",
            padding: "0 20px",
          })}
        >
          <Stack
            direction="row"
            gap={3}
            sx={{ alignItems: "center", mt: 8 }}
            justifyContent="space-between"
          >
            <Stack direction="row" gap={3} sx={{ alignItems: "center" }}>
              <Box
                sx={{
                  width: "40px", // Set width to make it square
                  height: "40px", // Set height to make it square
                  borderRadius: "8px",
                  border: "1px solid #EBEAED",
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer", // Change cursor on hover
                  transition: "background-color 0.3s", // Optional: add hover effect
                  "&:hover": {
                    backgroundColor: "#F0F0F0", // Optional: change background on hover
                  },
                }}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </Box>
            </Stack>
            <CopyLink
              link={`events/groups/${eventGroupData.event_group_id}`}
              suffixlabel="Share"
              customicon={
                <img
                  src={`${process.env.REACT_APP_CDN_BASE_URL}/images/share.png`}
                  style={{
                    width: "18px",
                    height: "18px",
                    objectFit: "contain",
                    marginRight: "4px",
                  }}
                />
              }
              style={{
                backgroundColor: "var(--white)",
                borderRadius: "8px",
                border: "1px solid #EBEAED",
                color: "var(--black)",
                fontSize: "14px",
              }}
            />
          </Stack>
          <Box style={{ position: "relative" }}>
            <StyledCardV2
              sx={(theme) => ({
                [theme.breakpoints.down("lt")]: { padding: `${theme.spacing(2)} !important` },
              })}
              pageType="events"
              eventseverity={eventGroupData?.severity}
            >
              <EventGroupCardHeader
                categoryLogo={eventGroupData?.category_logo_url}
                providerLogo={eventGroupData?.provider_logo_url}
                eventStatus={eventGroupData?.status}
                eventSeqId={eventGroupData?.formatted_event_group_sequence_id}
                eventStartTime={eventGroupData?.event_group_ts_start}
                eventEndTime={eventGroupData?.event_group_ts_end}
                numberOfEvents={eventGroupData?.number_of_events}
                type={eventGroupData?.type}
                eventCategory={eventGroupData?.category_display_name}
                providerDisplayName={eventGroupData?.provider_display_name}
                isDescriptionSectionEnabled={true}
                funnels={eventGroupData?.funnels}
                duration={calculateDuration()}
                timezone={timezone}
                source="view"
                {...eventGroupData}
              />
            </StyledCardV2>
          </Box>
          {eventGroupData?.type === EVENT_TYPE_ERROR &&
          BROWSER_EVENT_SUB_TYPES.includes(eventGroupData?.sub_type) ? (
            <React.Fragment>
              <Stack direction="column" gap={6}>
                <Stack
                  direction="row"
                  sx={(theme) => ({
                    justifyContent: "space-between",
                    gap: 4,
                    maxHeight: 350,
                  })}
                >
                  {eventGroupData?.details?.impacted_areas &&
                    eventGroupData?.details?.impacted_areas?.map((impacted_area, index) => (
                      <React.Fragment key={index}>
                        <EventDetailCards
                          sx={(theme) => ({ width: "50%", boxShadow: "0px 2px 4px 0px #00000014" })}
                          style={{ overflowY: "auto" }}
                          image={impacted_area.section_name}
                          title={impacted_area.section_display_name}
                          cardContentStyles={{
                            padding: 0,
                          }}
                        >
                          <Stack direction="column" sx={{ height: "100%", maxHeight: 250, mt: 5 }}>
                            <Stack direction="column" ml={2} gap={2}>
                              <Typography
                                sx={(theme) => ({ fontSize: theme.typography.pxToRem(14) })}
                              >
                                Total
                              </Typography>
                              <Typography
                                sx={(theme) => ({
                                  fontSize: theme.typography.pxToRem(20),
                                  fontWeight: typography.font_weight.mediumx,
                                })}
                              >
                                {impacted_area.total_count}
                              </Typography>
                            </Stack>
                            <EventsDistributionChart chart_data={impacted_area} />
                          </Stack>
                        </EventDetailCards>
                      </React.Fragment>
                    ))}
                </Stack>
                <Stack
                  direction="row"
                  sx={(theme) => ({
                    justifyContent: "space-between",
                    gap: 4,
                    maxHeight: 350,
                  })}
                >
                  {eventGroupData?.details?.affected_pages ? (
                  <EventDetailCards
                    sx={(theme) => ({ width: "100%", boxShadow: "0px 2px 4px 0px #00000014" })}
                    style={{ overflowY: "auto" }}
                    image={`event_affected_pages`}
                    title={`Affected Pages`}
                  >
                    {eventGroupData?.details?.affected_pages && (
                      <List sx={{ padding: 0, listStyleType: "disc" }}>
                        {eventGroupData?.details?.affected_pages?.map((line, index) => (
                          <ListItem key={index} sx={{ padding: 0, display: "list-item" }}>
                            <ListItemText
                              primary={
                                <Typography
                                  sx={(theme) => ({
                                    fontSize: theme.typography.pxToRem(14),
                                    fontWeight: typography.font_weight.regular,
                                    color: "var(--dark-grey)",
                                    whiteSpace: "pre-wrap", // Ensures line breaks are maintained
                                    wordBreak: "break-word", // Allows long words to break and wrap
                                  })}
                                >
                                  {line}
                                </Typography>
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </EventDetailCards>
                  ): null}
                  {eventGroupData?.details?.highlights &&
                    eventGroupData?.details?.highlights?.map((highlight, index) => (
                      <EventDetailCards
                        key={`${highlight.section_name}_${index}`}
                        sx={(theme) => ({ width: "100%", boxShadow: "0px 2px 4px 0px #00000014" })}
                        style={{ overflowY: "auto" }}
                        image={`${highlight.section_name}`}
                        title={`${highlight.section_display_name}`}
                      >
                        <List sx={{ padding: "0px 8px", listStyleType: "disc" }}>
                          {highlight?.items?.map((item, index) => (
                            <ListItem key={index} sx={{ padding: 0, display: "list-item" }}>
                              <ListItemText
                                primary={
                                  <Typography
                                    sx={(theme) => ({
                                      fontSize: theme.typography.pxToRem(14),
                                      fontWeight: typography.font_weight.regular,
                                      color: "var(--dark-grey)",
                                      whiteSpace: "pre-wrap",
                                      wordBreak: "break-word",
                                    })}
                                  >
                                    {item}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          ))}
                        </List>
                      </EventDetailCards>
                    ))}
                </Stack>
              </Stack>
            </React.Fragment>
          ) : null}
          {eventGroupData?.type !== EVENT_TYPE_OUTAGE ? (
            <Stack
              direction="row"
              sx={(theme) => ({
                justifyContent: "space-between",
                gap: 4,
              })}
            >
              <EventDetailCards
                sx={(theme) => ({ width: "50%", boxShadow: "0px 2px 4px 0px #00000014" })}
                style={{ overflowY: "auto" }}
                image={`event_meaning`}
                title={`What does this mean?`}
              >
                <List sx={{ padding: 0, listStyleType: "disc" }}>
                  {eventGroupData?.description?.split("\n").map((line, index) => (
                    <ListItem key={index} sx={{ padding: 0, display: "list-item" }}>
                      <ListItemText
                        primary={
                          <Typography
                            sx={(theme) => ({
                              fontSize: theme.typography.pxToRem(14),
                              fontWeight: typography.font_weight.regular,
                              color: "var(--dark-grey)",
                              whiteSpace: "pre-wrap", // Ensures line breaks are maintained
                              wordBreak: "break-word", // Allows long words to break and wrap
                            })}
                          >
                            {/* {line} Keep the leading "-" */}
                            {line?.replace(/^\s*-\s*/, "")}{" "}
                            {/* Remove leading dash and whitespace */}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </EventDetailCards>

              <EventDetailCards
                sx={(theme) => ({ width: "50%", boxShadow: "0px 2px 4px 0px #00000014" })}
                style={{ overflowY: "auto" }}
                image={`event_resolution`}
                title={`What can you do?`}
              >
                <List sx={{ padding: 0, listStyleType: "disc" }}>
                  {eventGroupData?.action?.split("\n").map((line, index) => (
                    <ListItem key={index} sx={{ padding: 0, display: "list-item" }}>
                      <ListItemText
                        primary={
                          <Typography
                            sx={(theme) => ({
                              fontSize: theme.typography.pxToRem(14),
                              fontWeight: typography.font_weight.regular,
                              color: "var(--dark-grey)",
                              whiteSpace: "pre-wrap", // Ensures line breaks are maintained
                              wordBreak: "break-word", // Allows long words to break and wrap
                            })}
                          >
                            {/* {line} Keep the leading "-" */}
                            {line?.replace(/^\s*-\s*/, "")}{" "}
                            {/* Remove leading dash and whitespace */}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </EventDetailCards>
            </Stack>
          ) : null}
          <Box sx={(theme) => ({ [theme.breakpoints.up("lt")]: { display: "none" } })}>
            <StyledCard>{renderTimeAndDuration()}</StyledCard>
          </Box>
          {eventGroupData?.events?.length > 0 ? (
            <EventDetailCards
              sx={(theme) => ({ width: "100%", boxShadow: "0px 2px 4px 0px #00000014" })}
              style={{ padding: "16px 20px" }}
              title={eventGroupData?.type !== EVENT_TYPE_OUTAGE ? `Error Logs` : `Outage History`}
              image={`event_error_details`}
            >
              <Box
                mt={7}
                mb={5}
                height="auto"
                sx={{
                  marginTop: 0,
                }}
              >
                <EventPayloadTable
                  events={eventGroupData?.events}
                  type={eventGroupData?.type}
                  sub_type={eventGroupData?.sub_type}
                />
              </Box>
            </EventDetailCards>
          ) : null}

          {eventGroupData?.type === EVENT_TYPE_OUTAGE && (
            <Box style={{ position: "relative" }}>
              <StyledCardV2>
                <StyledTitle>WHAT CAN YOU DO?</StyledTitle>
                <Divider orientation="horizontal" />
                <Box
                  mt={7}
                  mb={5}
                  height="auto"
                  sx={(theme) => ({
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    [theme.breakpoints.down("tm")]: { flexDirection: "column" },
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      className: "status-page",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "80%",
                      [theme.breakpoints.down("tm")]: { flexDirection: "column" },
                      padding: "40px 20px",
                      boxShadow: "2px 2px 4px 2px rgba(0, 0, 0, 0.25)",
                      cursor: "pointer",
                      margin: "0 10px",
                      borderRadius: "12px",
                      "&:hover .status-page-icon": {
                        transform: "scale(1.05)",
                        transition: "transform 0.2s",
                      },
                    })}
                    onClick={() => window.open(eventGroupData.provider_status_page_url, "_blank")}
                  >
                    <Typography
                      sx={(theme) => ({
                        fontWeight: typography.font_weight.semiBold,
                        [theme.breakpoints.down("tm")]: { fontSize: theme.typography.pxToRem(14) },
                      })}
                    >
                      {`Check ${eventGroupData.provider_display_name}'s Status Page`}
                    </Typography>
                    <IconButton
                      className="status-page-icon"
                      sx={(theme) => ({
                        color: colors.white,
                      })}
                      size="large"
                    >
                      <SensorsOutlined
                        sx={(theme) => ({
                          color: theme.palette.primary.main,
                          fontSize: theme.typography.pxToRem(90),
                        })}
                      ></SensorsOutlined>
                    </IconButton>
                  </Box>
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "80%",
                      [theme.breakpoints.down("tm")]: { flexDirection: "column" },
                      padding: "40px 20px",
                      boxShadow: "2px 2px 4px 2px rgba(0, 0, 0, 0.25)",
                      cursor: "pointer",
                      margin: "0 10px",
                      borderRadius: "12px",
                      "&:hover .home-page-icon": {
                        transform: "scale(1.05)",
                        transition: "transform 0.2s",
                      },
                    })}
                    onClick={() => window.open(eventGroupData.provider_home_page_url, "_blank")}
                  >
                    <Typography
                      sx={(theme) => ({
                        fontWeight: typography.font_weight.semiBold,
                        [theme.breakpoints.down("tm")]: { fontSize: theme.typography.pxToRem(10) },
                      })}
                    >
                      {`Visit ${eventGroupData.provider_display_name} Home Page`}
                    </Typography>
                    <IconButton
                      className="home-page-icon"
                      sx={(theme) => ({
                        color: colors.white,
                      })}
                      size="large"
                    >
                      <HomeRounded
                        sx={(theme) => ({
                          color: theme.palette.primary.main,
                          fontSize: theme.typography.pxToRem(90),
                        })}
                      ></HomeRounded>
                    </IconButton>
                  </Box>
                </Box>
              </StyledCardV2>
            </Box>
          )}
        </Stack>
      </Box>
    </L3ContainerMain>
  );
};

export default EventGroupCollaborateScreen;
